// IMPORT ADDONS
@import 'addons/bootstrap/bootstrap';
@import 'addons/datepicker/bootstrap-datetimepicker';
@import 'addons/font-awesome/font-awesome';
//@import 'addons/owl-carousel2/owl.carousel'
@import 'addons/layerslider/layerslider';
//@import 'addons/magnific-popup/magnific-popup'
//@import 'addons/cube-portfolio/cubeportfolio'

// IMPORT TEMPLATE
@import 'functions';

/*****************************************************/

// FONTS
$font-1: "Roboto";
$font-2: "";
$font-3: "";

// XXS Breakpoint
$xxs-min: 0px;
$xxs-max: 479px;

// XS Breakpoint
$xs-min: 480px;
$xs-max: 767px;

// SM Breakpoint
$sm-min: 768px;
$sm-max: 991px;

// MD Breakpoint
$md-min: 992px;
$md-max: 1199px;

// LG Breakpoint
$lg-min: 1200px;

//@media screen and (max-width: $xxs-max)
//@media screen and (max-width: $xs-max)
//@media screen and (max-width: $sm-max)
//@media screen and (max-width: $md-max)
//@media screen and (min-width: $lg-min)

/*****************************************************/

/* GENERAL TEMPLATE */
body {
	color: #000000;
	font: {
		size: 10px;
		weight: 400;
		family: $font-1; }
	line-height: 100%;
	a {
		text-decoration: none;
		&:hover {
			text-decoration: none; } }
	a[href^=tel], a[href^=mailto] {

		&:hover {} }

	p {}

	h1, h2, h3, h4, h5, h6 {}

	ul, ol {

		li {} }


	h1.referencement {
		display: none; }

	.appear {
		opacity: 0;
		@include transition(opacity 0.75s ease-in); }

	.parallax,
	.parallax-slow,
	.parallax-fast {
		background-attachment: fixed;
		@media screen and (max-width: $sm-max) {
			background-attachment: scroll; } } }

/*****************************************************/

// COMMON CLASSES

/* Boutons */
.btn-contact {
	background-color: #600000;
	border: 0;
	color: #fff;
	display: block;
	font-size: 18px;
	font-weight: 900;
	margin: 25px 0;
	padding: 15px 0;
	text-align: center;
	width: 230px;
	@include transition(all 0.2s ease);
	&:hover {
		background-color: #1c1716;
		color: #fff; } }

/* Burst */
.burst {
	margin-top: 60px;
	@media screen and (max-width: $xs-max) {
		margin-top: 0; } }

/* Colors */
.blanc {
	color: #fff; }

.orange {
	color: #600000; }

.noir {
	color: #1c1716; }

/* Images */
.img-center {
	margin: 0 auto; }


/* Padding */
.no-padding {
	padding: 0; }


/*****************************************************/

// ARTICLES

article {
	position: relative; }

.index {
	background: {
		attachment: fixed;
		color: #d0d0d0;
		image: url(../images/section01-bg.jpg);
		position: center;
		repeat: no-repeat;
		size: cover; }
	padding: 195px 0;
	.img-box {
		max-height: 450px;
		border-right: 10px solid #1c1716; }
	.text-box {
		h3 {
			font-size: 48px;
			font-weight: 900;
			line-height: 100%;
			margin: 0; }
		p {
			color: #1c1716;
			font-size: 23px;
			line-height: 48px;
			padding-top: 50px; } }
	@media screen and (max-width: $md-max) {
		padding: 280px 0 100px;
		.text-box {
			h3 {
				font-size: 36px; }
			p {
				font-size: 18px;
				line-height: 200%; } } }
	@media screen and (max-width: $sm-max) {
		background-attachment: none;
		background-image: none;
		padding: 50px 0;
		.text-box {
			h3 {
				font-size: 24px; }
			p {
				line-height: 24px;
				padding-top: 30px; } } } }

.contenu {
	background: {
		color: #d0d0d0;
		image: url(../images/section01-bg.jpg);
		position: center;
		repeat: no-repeat;
		size: cover; }
	padding: 195px 0;
	img {
		margin: 0 auto 10px; }
	.box-emphase {
		height: 225px;
		margin: 0 auto 10px;
		max-width: 481px;
		position: relative;
		width: 100%;
		h3 {
			color: #fff;
			font-size: 36px;
			font-weight: 900;
			left: 0;
			margin: 0;
			position: absolute;
			right: 0;
			text-align: center;
			top: 50%;
			@include transform(translateY(-50%)); }
		&:hover {
			&.crepi, &.estimation, &.infiltration {
				background-image: none; } }
		&.crepi {
			background-color: #605947;
			background-image: url(../images/burst-crepi.jpg); }
		&.estimation {
			background-color: #600000;
			background-image: url(../images/burst-estimation.jpg); }
		&.infiltration {
			background-color: #1c1716;
			background-image: url(../images/burst-infiltration.jpg); } }
	.text-box {
		border-left: 10px solid #1c1716;
		color: #1c1716;
		padding-left: 50px;
		h3 {
			font-size: 30px;
			font-weight: 900;
			line-height: 48px;
			margin: 0;
			padding-top: 70px; }
		p {
			font-size: 23px;
			line-height: 48px;
			padding-top: 20px; }
		.liste {
			list-style: none;
			padding: 50px 0 0;
			margin: 0;
			li {
				font-size: 23px;
				font-weight: 700;
				line-height: 24px;
				padding: 10px;
				text-indent: -0.6em;
				&:before {
					content: "• ";
					color: #600000;
					font-weight: 400; } } } }
	@media screen and (max-width: $sm-max) {
		background-attachment: none;
		background-image: none;
		padding: 50px 0;
		.box-emphase {
			height: 150px;
			max-width: 100%;
			h3 {
				font-size: 24px; } }
		.text-box {
			h3 {
				font-size: 24px;
				font-weight: 700;
				line-height: 100%;
				padding-top: 40px; }
			p {
				font-size: 18px;
				line-height: 24px;
				padding-top: 10px; }
			.liste {
				padding: 35px 0 0;
				li {
					font-size: 18px; } } } }
	@media screen and (max-width: $xs-max) {
		padding: 50px 0 0;
		.xs-no-padding {
			padding: 0; }
		.box-emphase {
			margin: 0 auto; }
		.text-box {
			border-left: none;
			padding: 0 0 35px; } }
	@media screen and (max-width: $xxs-max) {
		img {
			margin: 0 auto; } } }

/*****************************************************/

//FORMULAIRE

form {}
.form-box {
	border-left: 10px solid #1c1716;
	color: #1c1716;
	padding-left: 20px;
	p {
		font-size: 18px;
		line-height: 24px;
		padding: 15px 10px; }
	@media screen and (max-width: $xs-max) {
		border-left: none;
		padding-left: 0;
		padding-bottom: 25px; } }

.form-control {
	border: none;
	border-radius: 0;
	color: #1c1716;
	font-size: 18px;
	height: 40px; }

input::-webkit-input-placeholder {
	color: #1c1716 !important; }

input:-moz-placeholder {
	color: #1c1716 !important; }

input::-moz-placeholder {
	color: #1c1716 !important; }

input:-ms-input-placeholder {
	color: #1c1716 !important; }

textarea::-webkit-input-placeholder {
	color: #1c1716 !important; }

textarea:-moz-placeholder {
	color: #1c1716 !important; }

textarea::-moz-placeholder {
	color: #1c1716 !important; }

textarea:-ms-input-placeholder {
	color: #1c1716 !important; }

.input-group-addon {
	float: right;
	padding: 5px;
	margin-top: -30px; }

input[type="radio"], input[type="checkbox"] {
	margin: -2px 0 0;
	line-height: normal; }


.radio label, .checkbox label {
	min-height: 20px;
	padding-left: 35px;
	margin-bottom: 0;
	font-weight: normal;
	cursor: pointer; }


.radio-inline, .checkbox-inline {
	position: relative;
	display: inline-block;
	font-size: 18px;
	padding-left: 35px;
	margin-bottom: 0;
	vertical-align: middle;
	font-weight: normal;
	cursor: pointer; }

.checkbox {
	position: relative;
	display: block;
	margin-top: 15px;
	margin-bottom: 25px;
	font-size: 18px; }


/*****************************************************/

//CONTACT

.contact {
	.contact-box {
		padding: 190px 100px;
		.text {
			color: #1c1716;
			border-left: 10px solid #1c1716;
			padding-left: 20px;
			h3 {
				font-size: 30px;
				font-weight: 700;
				line-height: 48px;
				margin: 0;
				padding-bottom: 30px; }
			p {
				font-size: 23px;
				line-height: 48px;
				padding-top: 20px;
				.mail {
					color: #600000; } } } }
	@media screen and (max-width: $sm-max) {
		.contact-box {
			padding: 50px 0;
			.text {
				border-left: 0;
				padding-left: 0;
				h3 {
					font-size: 24px;
					line-height: 30px;
					padding-right: 30px; }
				p {
					font-size: 18px;
					line-height: 30px; } } } } }

/*****************************************************/


// MODULES

/* MENU */
.module-menu {
	top: 0;
	left: 0;
	right: 0;
	z-index: 99;
	position: fixed;
	background: rgba(0,0,0,0.9);
	.logo-container {
		float: left;
		img {
			@include transition(all 0.2s ease-out); } }
	[retour] {
		display: none !important; }
	.menu-toggle {
		display: none; }
	.main-menu {
		float: right;
		position: relative;
		@include transition(all 0.2s ease-out);
		.menu-list {
			bottom: 0;
			position: relative;
			.menu-ul {
				margin: 0;
				padding: 0;
				.menu-li {
					float: left;
					height: 120px;
					list-style: none;
					a {
						font-size: 20px;
						padding: 65px 22px 0;
						color: #ffffff;
						display: block;
						height: 100%;
						@include transition(color 0.2s ease-in);
						&.phone {
							color: #8c3223;
							font-size: 24px;
							font-weight: 700; }
						&.langue {
							background-color: #030f18;
							padding: 44px 30px; } }
					.submenu {
						margin: 0;
						padding: 0;
						position: absolute;
						transform-origin: top center;
						@include transform(scaleY(0));
						@include transition(all 0.2s ease-out);
						.sub-li {
							list-style: none;
							padding-top: 5px;
							a {
								color: #fff;
								padding: 15px;
								display: block;
								background: rgba(0,0,0,0.9);
								&:hover {
									background-color: #8c3223;
									color: #fff; } } } }
					&:hover {
						@media screen and (min-width: 1199px) {
							a {
								color: #8c3223; }
							.submenu {
								z-index: 10;
								@include transform(scaleY(1)); } } }
					&.active {
						@media screen and (min-width: 1199px) {
							a {
								color: #b0a9a6; } } } } } } }
	@media screen and (max-width: 1299px) {
		height: 60px;
		background: #000000;
		.logo-container {
			img {
				height: 60px;
				width: auto;
				margin: 0 auto; } }
		[retour] {
			opacity: 0.5;
			display: block !important; }
		.menu-toggle {
			display: block; }
		.main-menu {
			top: 60px;
			left: 0;
			right: 0;
			bottom: 0;
			position: fixed;
			background: #000;
			@include transform(translateX(-100%));
			.menu-list {
				top: 50%;
				left: 50%;
				display: table;
				position: absolute;
				width: 100%;
				@include transform(translate(-50%, -50%));
				.menu-ul {
					.menu-li {
						float: none;
						height: 100%;
						display: block;
						text-align: center;
						a {
							padding: 10px 0; }
						.submenu {
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							position: fixed;
							background: #000;
							.sub-li {
								a {
									padding: 8px 5px; } }
							&.activated {
								z-index: 10;
								@include transform(scaleY(1)); } } } } }
			&.toggled {
				@include transform(translateX(0%)); } }
		.menu-toggle {
			float: right;
			cursor: pointer;
			padding: 20px 18px; } } }


/* Slideshow */
.module-slideshow {
	position: relative;
	.title {
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		display: block;
		margin: 0 auto;
		max-width: 1020px;
		text-align: center;
		z-index: 10;
		@include transform(translateY(-50%));
		&.page {
			max-width: 100%; }
		h2 {
			color: #fff;
			font-size: 72px;
			font-weight: 900;
			margin: 0;
			text-shadow: 0px 0px 10px rgba(0, 0, 0, 1); } }
	@media screen and (max-width: $sm-max) {
		margin-top: 60px;
		.title {
			padding: 0 15px;
			h2 {
				font-size: 30px; } } } }


/* Navigation Slideshow */
.ls-noskin .ls-nav-sides, .ls-noskin .ls-bottom-nav-wrapper, .ls-noskin .ls-bottom-slidebuttons a, .ls-noskin .ls-nav-prev, .ls-noskin .ls-nav-next, .ls-noskin .ls-nav-start, .ls-noskin .ls-nav-stop {
	display: block !important;
	visibility: visible !important; }

html * .ls-nav-prev, html * .ls-nav-next, html * .ls-container img, html * .ls-bottom-nav-wrapper a, html * .ls-container .ls-fullscreen, body * .ls-nav-prev, body * .ls-nav-next, body * .ls-container img, body * .ls-bottom-nav-wrapper a, body * .ls-container .ls-fullscreen, #ls-global * .ls-nav-prev, #ls-global * .ls-nav-next, #ls-global * .ls-container img, #ls-global * .ls-bottom-nav-wrapper a, #ls-global * .ls-container .ls-fullscreen, html * .ls-thumbnail a, body * .ls-thumbnail a, #ls-global * .ls-thumbnail a {
	border: 0 none;
	line-height: normal;
	outline: medium none;
	padding: 0;
	transition: none 0s ease 0s; }

.ls-nav-prev,
.ls-nav-next {
	display: block !important;
	height: 60px;
	position: absolute;
	top: 50%;
	width: 40px;
	z-index: 50;
	@media screen and (max-width: $sm-max) {
		display: none !important; } }

.ls-nav-prev {
	background: {
		image: url(../images/ss-prev.png);
		position: center;
		repeat: no-repeat; }
	left: 20px;
	margin: auto;
	&:hover {
		background-image: url(../images/ss-prev-hover.png); } }

.ls-nav-next {
	background: {
		image: url(../images/ss-next.png);
		position: center;
		repeat: no-repeat; }
	right: 20px;
	margin: auto;
	&:hover {
		background-image: url(../images/ss-next-hover.png); } }


/* INDEX - Top Contact */
.module-top-contact {
	float: left;
	margin-top: -180px;
	position: relative;
	width: 100%;
	z-index: 10;
	.item-box {
		float: left;
		text-align: center;
		width: 50%;
		h3 {
			font-size: 48px;
			font-weight: 900;
			line-height: 100%;
			margin: 0;
			padding: 30px 0 20px;
			.petit {
				font-size: 24px;
				text-transform: none; } }
		.soumission {
			background: {
				image: url(../images/mod-soumission-bg.jpg);
				position: center;
				repeat: no-repeat;
				size: cover; }
			height: 360px;
			&:hover {
				background-image: url(../images/mod-soumission-bg-hover.jpg);
				.petit {
					color: #fff; } } }
		.contact {
			background: #fff;
			height: 360px; } }
	@media screen and (max-width: $sm-max) {
		float: none;
		margin-top: 0;
		.item-box {
			float: none;
			width: 100%;
			h3 {
				font-size: 36px;
				padding: 20px 0;
				.petit {
					font-size: 18px; } }
			.soumission, .contact {
				height: 156px; } } } }

/* INDEX - TOP EMPHASES */
.module-top-emphases {
	background-color: #000;
	padding: 100px 0 60px;
	.item {
		cursor: pointer;
		height: 160px;
		margin-bottom: 20px;
		max-width: 480px;
		position: relative;
		@include transition(all 0.25s ease-in);
		&.crepi {
			background: {
				image: url(../images/crepi.jpg);
				position: center;
				repeat: no-repeat;
				size: cover; } }
		&.brique {
			background: {
				image: url(../images/brique.jpg);
				position: center;
				repeat: no-repeat;
				size: cover; } }
		&.surfacage {
			background: {
				image: url(../images/surfacage.jpg);
				position: center;
				repeat: no-repeat;
				size: cover; } }
		&.fissure {
			background: {
				image: url(../images/fissure.jpg);
				position: center;
				repeat: no-repeat;
				size: cover; } }
		&.trottoir {
			background: {
				image: url(../images/trottoir.jpg);
				position: center;
				repeat: no-repeat;
				size: cover; } }
		&.garage {
			background: {
				image: url(../images/accueil_section01_burst06.jpg);
				position: center;
				repeat: no-repeat;
				size: cover; } }
		h3 {
			color: #fff;
			font-size: 36px;
			font-weight: 900;
			left: 0;
			margin: 0;
			position: absolute;
			right: 0;
			text-align: center;
			text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
			top: 50%;
			@include transform(translateY(-50%)); }
		&:hover {
			&.crepi {
				background-image: url(../images/crepi-hover.jpg); }
			&.brique {
				background-image: url(../images/brique-hover.jpg); }
			&.surfacage {
				background-image: url(../images/surfacage-hover.jpg); }
			&.fissure {
				background-image: url(../images/fissure-hover.jpg); }
			&.trottoir {
				background-image: url(../images/trottoir-hover.jpg); }
			&.garage {
				background-image: url(../images/accueil_section01_burst06_hover.jpg); } } }
	@media screen and (max-width: $xs-max) {
		padding: 50px 0;
		.item {
			max-width: 100%; } } }

/* INDEX - Module emphases */
.module-emphases-index {
	.emphase {
		float: left;
		height: 480px;
		position: relative;
		width: 100%;
		h3 {
			color: #fff;
			font-size: 48px;
			font-weight: 900;
			left: 0;
			line-height: 60px;
			margin: 0;
			position: absolute;
			right: 0;
			text-align: center;
			top: 50%;
			text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
			@include transform(translateY(-50%));
			.gros {
				font-size: 72px; } }
		&:hover {
			&.left {
				background-image: none; }
			&.right {
				background-image: none;
				&.infiltration {
					background-image: none; } } }
		&.left {
			background: {
				color: #600000;
				image: url(../images/burst1-bg.jpg);
				position: center;
				repeat: no-repeat;
				size: cover; } }
		&.right {
			background: {
				color: #161211;
				image: url(../images/accueil_section03_burst02.jpg);
				position: center;
				repeat: no-repeat;
				size: cover; }
			&.infiltration {
				background-image: url(../images/page-plancher-infiltration-bg.jpg); } } }
	@media screen and (max-width: $md-max) {
		.emphase {
			height: 320px;
			h3 {
				font-size: 36px;
				line-height: 100%;
				.gros {
					font-size: 60px; } } } }
	@media screen and (max-width: $sm-min) {
		.emphase {
			height: 160px;
			h3 {
				font-size: 24px;
				line-height: 30px;
				.gros {
					font-size: 30px; } } } } }

/* INDEX - Module bottom */
.module-bottom-index {
	padding: 160px 0 124px;
	.asso-box {
		height: 120px;
		margin: 20px 0;
		border: 1px solid #9c8f8c;
		position: relative;
		&.first {
			border-right: 0; }
		.asso {
			margin: 0 auto;
			top: 50%;
			position: absolute;
			left: 0;
			right: 0;
			transform: translateY(-50%); } }
	.btn-lien {
		display: table-cell;
		vertical-align: middle;
		font-size: 30px;
		font-weight: 900;
		height: 118px;
		margin-bottom: 20px;
		position: relative;
		width: 1070px;
		&:hover {
			&.crepi, &.dark-contact {
				background-color: #600000; }
			&.dark-contact {
				color: #1c1716;
				&:before {
					border-bottom: 1px solid #1c1716; } } }
		&.crepi {
			background-color: #9c8f8c;
			color: #1c1716;
			padding-left: 52px;
			&:before {
				content: "";
				position: absolute;
				height: 1px;
				border-bottom: 1px solid #1c1716;
				left: 0;
				top: 50%;
				width: 40px;
				@include transform(translateY(-50%)); } }
		&.dark-contact {
			background-color: #1c1716;
			color: #9c8f8c;
			padding-left: 52px;
			&:before {
				content: "";
				position: absolute;
				height: 1px;
				border-bottom: 1px solid #9c8f8c;
				left: 0;
				top: 50%;
				width: 40px;
				@include transform(translateY(-50%)); } } }
	.liste-box {
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				border-bottom: 1px solid #000000;
				color: #000000;
				font-size: 24px;
				font-weight: 900;
				line-height: 48px;
				padding-left: 20px;
				text-transform: uppercase;
				&:before {
					color: #c64029;
					content: "• "; } } } }
	@media screen and (max-width: $md-max) {
		.btn-lien {
			&.crepi, &.dark-contact {
				font-size: 24px; } } }
	@media screen and (max-width: $sm-max) {
		padding: 50px 0;
		.asso-box {
			margin: 10px 0;
			&.first {
				border-right: 1px solid #9c8f8c; }
			&:last-child {
				margin-top: 0; } }
		.btn-lien {
			height: 80px;
			&:hover {
				&.crepi, &.dark-contact {
					&:before {
						border-bottom: 0; } } }
			&.crepi, &.dark-contact {
				font-size: 18px;
				padding-left: 0;
				text-align: center;
				&:before {
					border-bottom: 0; } } }
		.liste-box {
			padding-bottom: 26px;
			ul {
				li {
					padding-left: 0;
					font-size: 16px; } } } } }

/* Module fournisseurs */
.module-fournisseurs {
	background-color: #ebe9e8;
	padding: 110px 0;
	img {
		display: inline-block;
		margin: 0 15px; }
	@media screen and (max-width: $sm-max) {
		padding: 50px 0;
		img {
			margin: 10px auto; } } }

/* Module Cheminée */
.module-cheminee {
	background: {
		image: url(../images/bg-cheminee.jpg);
		position: center;
		repeat: no-repeat;
		size: cover; }
	padding: 180px 0;
	.text-box {
		border-left: 10px solid #1c1716;
		color: #1c1716;
		padding-left: 50px;
		h3 {
			font-size: 30px;
			font-weight: 900;
			line-height: 48px;
			margin: 0; }
		.liste {
			list-style: none;
			padding: 50px 0 0;
			margin: 0;
			li {
				font-size: 23px;
				font-weight: 700;
				line-height: 24px;
				padding: 10px;
				text-indent: -0.6em;
				&:before {
					content: "• ";
					color: #600000;
					font-weight: 400; } } } }
	@media screen and (max-width: $sm-max) {
		padding: 50px 0;
		.text-box {
			border-left: 0;
			padding-left: 0;
			h3 {
				font-size: 24px;
				line-height: 100%; }
			.liste {
				padding: 35px 0;
				li {
					font-size: 18px; } } } } }

/*****************************************************/

// FOOTER

.module-footer {
	position: relative;
	.copyright {
		background: #1c1716;
		color: #909090;
		font-size: 14px;
		font-weight: 300;
		line-height: 20px;
		padding: 28px 15px;
		text-align: center;
		img {
			margin-top: -6px;
			display: inline; } } }
